import React, {useState, useRef, useEffect, Fragment, Children, useContext} from 'react'
import MenuWrapper from './menuwrapper'
import { SubsetContext } from '../../contexts/subset'

// This component exists just to get unique attrs and then display the matching MenuWrappers

const MenuSubset = ({children, layer, noExpand, type, paverReg, setCenter, subset, editLayers, defaultLabel, updateObj, setUpdateObj}) => {
  const [subsetItems, setSubsetItems] = useState([])
  const subsetContext = useContext(SubsetContext)
  const isMounted = useRef(false)

  let where = type
  if (subsetContext){
    where += subsetContext
  }

  useEffect(() => {
    let allAttrs = []
    // First, if there is a subset requirement, get all unique values for that subset
    fetch(layer.url+"/query", {
      method: 'post',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      body: new URLSearchParams({
        f: "json",
        token: paverReg.token,
        where: where,
        outFields: subset,
        returnGeometry: false,
        returnDistinctValues: true
      })
    }).then(resp => resp.json()).then(output => {
      output.features.forEach(item => {
        allAttrs.push(item.attributes[subset])
      })
      if (isMounted.current){
        setSubsetItems(allAttrs)
      }
    })
    isMounted.current = true
    return () => { isMounted.current = false }
  }, [])

  // If it's all undefined, hide this
  if (subsetItems.length === 0){
    return null
  }

  // Make sure there's only 1 req per year for MorEnd case
  let finalSubset = subsetItems
  if (subset === "MorEnd"){
    let newSubset = [/*null*/] // I don't think we need null because these lines wouldn't render anyway
    for (let item in subsetItems){
      let thisItem = subsetItems[item]
      if (thisItem){
        let date = new Date(parseInt(thisItem))
        let year = date.getFullYear()
        if (newSubset.indexOf(year) === -1){
          newSubset.push(year)
        }
      }
    }
    // Swap in the updated subset
    finalSubset = newSubset
  }

  return (
    <div className="menu-subset">
      {finalSubset.map((item, index) => {
        let testValue = ""
        let apos = ""
        if (typeof item === "number"){
          testValue = item
        } else if (item === null){
          testValue = "NULL"
        } else {
          testValue = item
          apos = "'" // Required for strings
        }
        let fallbackLabel = "Uncategorized"
        if (defaultLabel){
          fallbackLabel = defaultLabel
        }

        // Annoyingly, date #s need to be transformed into date strings to properly query, so here we go
        let date;
        let oper = "="
        if (subset === "MorEnd" && item && item !== "NULL"){
          date = parseInt(testValue)
          const oneYear = date + 1
          testValue = `DATE '${date}-01-01' AND DATE '${oneYear}-01-01'`
          oper = "BETWEEN"
        }

        // Set the where clause
        let whereAdd = ` AND ${subset} ${oper} ${apos}${testValue}${apos}`

        // Now change it back to something nice for display
        if (date){
          testValue = date.toString()
        }

        return <MenuWrapper 
          key={"subset"+index}
          layer={layer}
          type={type}
          paverReg={paverReg}
          showCount={true}
          noExpand={Children.count(children) === 0}
          setCenter={setCenter}
          whereAdd={whereAdd}
          titleOverride={!item ? fallbackLabel : testValue}
          updateObj={updateObj}
          setUpdateObj={setUpdateObj}
        >
          <SubsetContext.Provider value={whereAdd}>
            {children}
          </SubsetContext.Provider>
        </MenuWrapper>
      })}
    </div>
  )
}

export default MenuSubset