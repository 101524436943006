import React, {useEffect, useState, useRef, Fragment} from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import ComboBox from 'react-responsive-combo-box'
import 'react-responsive-combo-box/dist/index.css'
import { hiddenFields, nonInteractiveFields, typeConditionMap } from '../../utils/constants'
import { setLocalStorage, getLocalStorage, getPages } from '../../utils/esri-helpers'

/* A modal to handle queries and filters */

// Function just to stop the click from bubbling out and triggering the background exit
let interceptClick = (e) => {
  e.stopPropagation()
}

// Chain of promises refresh
let promiseChain = []

const QueryModal = ({setShowQueryModal, setQueryResult, paverReg, editLayers, modules, view}) => {
  let modalRef = useRef(null)
  let extentRef = useRef(null)
  let [fade, setFade] = useState("")
  // Layer select
  let [queryLayer, setQueryLayer] = useState(null)
  let [attrObj, setAttrObj] = useState({}) // Contains all the keys for this layer
  let [attrChoices, setAttrChoices] = useState([]) // Contains all the condition selections
  let [queryYield, setQueryYield] = useState([])
  let [queryProgress, setQueryProgress] = useState(null) // Show a loading sign when we're asking ArcGIS for the query
  let [whereString, setWhereString] = useState("")

  let handleDefaultSet = ({clearFields}) => {
    if (queryLayer){
      // Set priorMinScale on this layer so we can revert it when we're done
      if (!editLayers[queryLayer].priorMinScale){
        editLayers[queryLayer].priorMinScale = editLayers[queryLayer].minScale
      }

      // Set the layer being queried to minScale = 0 so the layer can show in query
      editLayers[queryLayer].minScale = 0

      let newObj = {}
      for (let attr in editLayers[queryLayer].fields){
        let thisAttr = editLayers[queryLayer].fields[attr]
        if (hiddenFields.indexOf(thisAttr.name) === -1){
          newObj[thisAttr.alias] = {
            type: thisAttr.type,
            name: thisAttr.name
          }
        }
      }
      setAttrObj(newObj)

      // Set an initial attr choice if we have nothing saved
      if (attrChoices.length === 0 || clearFields){
        setAttrChoices([{
          name: Object.keys(newObj)[0],
          operator: "is equal",
          value: "",
          nullcheck: false
        }])
      }
    }
  }

  // On component init, change classes so it fades in
  useEffect(() => {
    // Fade it in
    setFade("show")

    // Lock body scroll
    if (modalRef.current){
      disableBodyScroll(modalRef.current)
    }

    // When this opens, set initial upload layer
    let savedLayer = getLocalStorage("paverops_querymodal", "layer")
    if (!editLayers[savedLayer]){
      savedLayer = null
      // Clear attrs so they don't mess us up
      setLocalStorage('paverops_querymodal', 'attrs', [])
    }

    if (!savedLayer){
      // No saved layer, so use the first layer
      // TODO: Set the initial upload layer to the user's first layer with edit permissions
      savedLayer = Object.keys(editLayers)[0]
    }
    setQueryLayer(savedLayer)

    // Set initial conditions
    let savedAttrs = getLocalStorage("paverops_querymodal", "attrs")
    if (savedAttrs){
      // Purge the empty objects on reload
      savedAttrs = savedAttrs.filter((item) => {
        return Object.keys(item).length > 0
      })
      // Only set it here if we have a value for it
      setAttrChoices(savedAttrs)
    }

    // Enable tooltip
    modules.Tooltip.bindEvents()

    // Get the map extent so we don't have to do it on the fly
    if (view && view.extent){
      extentRef.current = modules.Polygon.fromExtent(view.extent)
    }

    // Enable on cleanup
    return () => {
      clearAllBodyScrollLocks()
      promiseChain = []
    }
  }, [])

  // When the query layer changes, we need to go grab the attributes from the new layer
  useEffect(() => {
    handleDefaultSet({clearFields: false})
  }, [queryLayer])

  useEffect(() => {
    // Track what this query would yield
    if (queryLayer && Object.keys(attrObj).length > 0 && attrChoices.length > 0){
      // Show search indicator
      setQueryProgress(1)
      // When attrChoices changes, check the query to see the features we'll be selecting
      let whereExpression = ""
      for (let choice in filteredChoices){
        let thisChoice = filteredChoices[choice]
        // Get the real field name
        if (!attrObj[thisChoice.name]){
          continue
        }
        const realName = attrObj[thisChoice.name].name

        // Get the type so we know if we need a string or int treatment
        const realType = attrObj[thisChoice.name].type
        // If type is int but value is falsey, set to zero
        let submitVal = thisChoice.value
        if (!submitVal && ["double", "integer"].indexOf(realType) !== -1){
          submitVal = 0
        }

        // Change the plaintext operator to the SQL one
        let operatorConversion;
        let wildcards = false
        switch(thisChoice.operator){
          case "is equal": operatorConversion = "="; break;
          case "is not equal": operatorConversion = "<>"; break;
          case "is greater than": operatorConversion = ">"; break;
          case "is less than": operatorConversion = "<"; break;
          case "is after": operatorConversion = ">"; break;
          case "is before": operatorConversion = "<"; break;
          case "contains": operatorConversion = "LIKE"; wildcards = true; break;
          case "does not contain": operatorConversion = "NOT LIKE"; wildcards = true; break;
        }
        let addQuotes = false
        if (realType === "string" || realType === "date"){
          addQuotes = true
        }
        let outString = `${realName} ${operatorConversion} ${realType === "date" ? "DATE " : ""}${addQuotes ? "'" : ""}${wildcards ? "%" : ""}${submitVal}${wildcards ? "%" : ""}${addQuotes ? "'" : ""}`

        // Add the NULL option if box is checked
        if (thisChoice.nullcheck){
          outString = `(${outString} OR ${realName} = NULL)`
        }

        // We can concatenate our queries
        let andString = ""
        if (choice > 0){
          andString = " AND "
        }
        whereExpression += (andString + outString)
      }
      //console.log("whereExpression", whereExpression)
      // Set this for use in the query all button
      setWhereString(whereExpression)

      // Restrict queries to just visible features:
      //whereExpression += ` AND (VisibleAreas LIKE '%|${paverReg.id}|%' OR MemberId = ${paverReg.id})`
      // Actually, just use the definitionExpression, duh
      whereExpression += ` AND ` + editLayers[queryLayer].definitionExpression

      const layerPromise = new Promise((resolveLayer, rejectLayer) => {
        let thisLayer = editLayers[queryLayer]
        //console.log("editLayers[queryLayer]", editLayers[queryLayer])
        // If the queried layer is not visible, make it visible
        if (!thisLayer.visible){
          thisLayer.visible = true
        }
        // If the parent of that is not visible, make it visible
        if (thisLayer.parent && !thisLayer.parent.visible){
          thisLayer.parent.visible = true
        }

        // If we didn't already get extentRef, try again
        if (!extentRef.curret && view){
          extentRef.current = modules.Polygon.fromExtent(view.extent)
        } else if (!view){
          resolveLayer(null)
          return null
        }

        // Use visible extent INSTEAD of layerView, layerView is for suckers
        // Function to page through results
        // NOTE: We no longer have to do two queries because we beat ArcGIS' game
        getPages(paverReg.token, editLayers[queryLayer], whereExpression, null, null, extentRef.current, true).then((resp) => {
          if (promiseChain.indexOf(layerPromise) === promiseChain.length - 1){
            // Yield the features for selection
            setQueryYield(resp)
            setQueryProgress(null)
            //console.log("RESULT OF THE QUERY", resp)
            // Resolve and finish query
            resolveLayer(resp)
          } else {
            // Resolve but don't do anything
            resolveLayer(null)
          }
        })
      })

      // Now if we want to call more pages, we can loop and push 
      promiseChain.push(layerPromise)

    }
  }, [attrChoices, attrObj])

  const registerInput = (e, attrChoices, index, itemType) => {

    // Swap out this index when this field is changed
    let newChoices = JSON.parse(JSON.stringify(attrChoices))
    newChoices[index] = {
      name: newChoices[index].name,
      operator: newChoices[index].operator,
      value: e.currentTarget.value,
      nullcheck: newChoices[index].nullcheck 
    }
    setAttrChoices(newChoices)
    setLocalStorage('paverops_querymodal', 'attrs', newChoices)
  }

  let filteredChoices = attrChoices.filter((item) => Object.keys(item).length > 0)

  return (
    <div className={"modal-wrapper " + fade} onMouseDown={() => {
      // If we have a prior minscale to set, do it
      if (editLayers[queryLayer]){
        editLayers[queryLayer].minScale = editLayers[queryLayer].priorMinScale
      }
      setShowQueryModal(false)
    }}>
      <div className="drop-modal floating" ref={modalRef} onMouseDown={interceptClick}>
        <div style={{position: "absolute", top: "10px", left: "10px"}} dangerouslySetInnerHTML={{__html: `<div class="question" style="float: right;" rel="tooltip" data-helptext="Select a layer to perform a search and select against. If multiple conditions are set, features that meet all conditions are returned. Query Parameters are stored after a query is performed and available when the tool is reopened. The existing conditions can be modified or removed and conditions can be cleared by clicking “Clear Fields”."></div>`}} />
        <div className="data-entry query-entry">
          <div className="drop-options">
            <p>Choose a layer and build your search query</p>
            <button className="critical small" onClick={() => {
              handleDefaultSet({clearFields: true})
            }}>Clear fields</button>
            <div className="field-box">
              <label htmlFor={"layer"}>
                <span>Search Layer</span>
              </label>
              {queryLayer &&
                <ComboBox 
                  // TODO: Limit the layers to only the editable ones for this user
                  options={Object.keys(editLayers)}
                  editable={false}
                  onSelect={(option) => {
                    // If we have a prior minscale to set, do it
                    editLayers[queryLayer].minScale = editLayers[queryLayer].priorMinScale
                    setQueryLayer(option)
                    setAttrChoices([]) // Clear attr choices
                    setLocalStorage('paverops_querymodal', 'layer', option)
                    // Clear the saved attrs if we've changed layers
                    setLocalStorage('paverops_querymodal', 'attrs', [])
                  }}
                  defaultValue={queryLayer}
                  className="combo"
                />
              }
              {attrChoices.length > 0 && Object.keys(attrObj).length > 0 && attrChoices.map((condition, index) => {
                // Ignore if this is a blank entry
                if (Object.keys(condition).length === 0 || !attrObj[condition.name]){
                  return null
                }

                let itemType = attrObj[condition.name].type
                // Get number of blanks so we can keep the condition number correct
                let blanks = attrChoices.slice(0, index).filter((item) => {
                  return Object.keys(item).length === 0
                })
                // Make sure the input is formatted correctly
                let inputType = "text"
                if (itemType === "double" || itemType === "small-integer"){
                  inputType = "number"
                } else if (itemType === "date"){
                  inputType = "date"
                }
                //console.log("itemType", itemType)
                return <div className="condition-row" key={index+"fieldbox"}>
                  <div className="field-box">
                    <label>
                      <span>Condition {index + 1 - blanks.length}</span>
                      {filteredChoices.length > 1 &&
                        <span title="Remove this condition" className="minus-condition" onClick={(e) => {
                          let newChoices = JSON.parse(JSON.stringify(attrChoices))
                          // Instead of splicing, let's try just replacing with blank
                          // We do this so React doesn't get confused on the remove step
                          newChoices[index] = {}
                          setAttrChoices(newChoices)
                          setLocalStorage('paverops_querymodal', 'attrs', newChoices)
                        }}>
                          -
                        </span>
                      }
                    </label>
                    {Object.keys(attrObj).length > 0 &&
                      <ComboBox 
                        options={Object.keys(attrObj)}
                        editable={false}
                        onSelect={(option) => {
                          //console.log(option)
                          // Swap out this index when this field is changed
                          let newChoices = JSON.parse(JSON.stringify(attrChoices))
                          newChoices[index] = {
                            name: option,
                            operator: "is equal",
                            value: "",
                            nullcheck: false
                          }
                          setAttrChoices(newChoices)
                          setLocalStorage('paverops_querymodal', 'attrs', newChoices)
                        }}
                        defaultValue={condition.name}
                        className="combo"
                      />
                    }
                    {typeConditionMap[itemType] && typeConditionMap[itemType].length > 0 &&
                      <ComboBox
                        key={index+typeConditionMap[itemType]}
                        options={typeConditionMap[itemType]}
                        editable={false}
                        onSelect={(option) => {
                          //console.log(option)
                          // Swap out this index when this field is changed
                          let newChoices = JSON.parse(JSON.stringify(attrChoices))
                          newChoices[index] = {
                            name: newChoices[index].name,
                            operator: option,
                            value: newChoices[index].value,
                            nullcheck: newChoices[index].nullcheck
                          }
                          setAttrChoices(newChoices)
                          setLocalStorage('paverops_querymodal', 'attrs', newChoices)
                        }}
                        defaultValue={condition.operator}
                        className="combo"
                      />
                    }
                    {(itemType === "integer" || itemType === "small-integer") &&
                      <input key={index+itemType} type="number" min="0" step="1" placeholder="Enter value" value={condition.value} onChange={(e) => {registerInput(e, attrChoices, index)}} />
                    }
                    {(itemType === "double" || itemType === "string" || itemType === "date") &&
                      <input 
                        key={index+itemType} 
                        type={inputType} 
                        placeholder="Enter value" 
                        value={condition.value} 
                        onChange={(e) => {registerInput(e, attrChoices, index)}} 
                      />
                    }
                    <div className="null-check-row">
                      <span>Include NULL values?</span>
                      <input 
                        key={index+"checkbox"} 
                        type="checkbox" 
                        value={condition.nullcheck} 
                        checked={condition.nullcheck}
                        onChange={(e) => {
                          let newChoices = JSON.parse(JSON.stringify(attrChoices))
                          newChoices[index] = {
                            name: newChoices[index].name,
                            operator: newChoices[index].operator,
                            value: newChoices[index].value,
                            nullcheck: e.target.checked
                          }
                          setAttrChoices(newChoices)
                          setLocalStorage('paverops_querymodal', 'attrs', newChoices)
                        }} 
                      />
                     </div>
                  </div>
                </div>
              })}
            </div>
            <div className="plus-condition" title="Add another condition" onClick={() => {
              let newChoices = JSON.parse(JSON.stringify(attrChoices))
              newChoices.push({
                name: Object.keys(attrObj)[0],
                operator: "is equal",
                value: "",
                nullcheck: false
              })
              setAttrChoices(newChoices)
            }}>
              + Add condition
            </div>

            {queryProgress ? (
              <p className="query-preview">Calculating query results... {/*<br/>Fetching page {queryProgress}*/}</p>
            ) : (
              <p className="query-preview">This search would return <strong>{queryYield.length.toLocaleString()}</strong> feature{queryYield.length !== 1 ? "s" : ""} in the map's current extent.</p>
            )}
            
            <button className="primary" onClick={(e) => {
              e.stopPropagation()
              if (queryProgress !== null){
                return false
              }
              // Use the query function on the layer to execute the select
              setQueryResult({[queryLayer]: queryYield})
              // If we have a prior minscale to set, do it
              editLayers[queryLayer].minScale = editLayers[queryLayer].priorMinScale
              setShowQueryModal(false)
            }}>
              {queryProgress ? (
                <span className="loading">
                  <img src="/loading.png" alt="Loading icon" />
                </span>
              ) : (
                <span>Search current map extent</span>
              )}
            </button>

            <button className="primary" onClick={(e) => {
              e.stopPropagation()
              if (queryProgress !== null){
                return false
              }
              // Incorportate definition query
              let whereExpression = whereString
              if (editLayers[queryLayer] && editLayers[queryLayer].definitionExpression){
                whereExpression = whereString + ` AND ` + editLayers[queryLayer].definitionExpression
              }
              // Zoom out to the extent first
              getPages(paverReg.token, editLayers[queryLayer], whereExpression, null, "*", modules.boundaryGeo).then((resp) => {
                // Use the query function on the layer to execute the select
                setQueryResult({[queryLayer]: resp})
                //console.log("RESULT OF THE QUERY", resp)
                // If we have a prior minscale to set, do it
                editLayers[queryLayer].minScale = editLayers[queryLayer].priorMinScale
                setShowQueryModal(false)
              })
            }}>
              {queryProgress ? (
                <span className="loading">
                  <img src="/loading.png" alt="Loading icon" />
                </span>
              ) : (
                <span>Search service area</span>
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default QueryModal