import React, {useEffect, useState, useRef, Fragment} from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import 'react-responsive-combo-box/dist/index.css'

/* A modal to handle showing what went wrong with an upload */

// Function just to stop the click from bubbling out and triggering the background exit
let interceptClick = (e) => {
  e.stopPropagation()
}

const ErrorModal = ({showErrorModal, setShowErrorModal}) => {
  let modalRef = useRef(null)
  let [fade, setFade] = useState("")
  
  // On component init, change classes so it fades in
  useEffect(() => {
    // Fade it in
    setFade("show")

    // Lock body scroll
    if (modalRef.current){
      disableBodyScroll(modalRef.current)
    }

    // Enable on cleanup
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <div className={"testing modal-wrapper " + fade} onMouseDown={() => {setShowErrorModal("")}}>
      <div className="drop-modal floating" ref={modalRef} onMouseDown={interceptClick}>
        <div className="data-entry query-entry">
          <div className="drop-options">
            <p>Warning! Your upload resulted in some errors, detailed below. The features that were able to upload successfully have been selected for you. If you want to retry the full upload, you may want to delete this selection and upload the same file again.</p>
            <div className="error-text" dangerouslySetInnerHTML={{__html: showErrorModal}} />
            <button className="secondary" onClick={(e) => {
              e.stopPropagation()
              setShowErrorModal("")
            }}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ErrorModal