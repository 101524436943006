import React, {Fragment} from 'react'
import MenuWrapper from './menuwrapper'
import MenuSubset from './menusubset'
import { generateFilter } from '../../utils/constants.js'
// import {useWhyDidYouUpdate} from '../../utils/esri-helpers'

const MenuSection = (props) => {
  let {children, type, setCenter, paverReg, groupLayers, editLayers, token, whereAdd, subset, otherOrgOuter, updateObj, setUpdateObj, limit} = props

  let sortingArr = []
  let groupObj = {} // Keep track of which group each layer is in
  if (groupLayers){
    groupLayers.forEach((parent) => {
      let layerItems = parent.layers
      for (let item in layerItems){
        let thisItem = layerItems[item]
        sortingArr.push(thisItem.title)

        // Save the parent to an object for easy reference
        groupObj[thisItem.title] = parent.title
      }
    })
  }
  sortingArr.reverse()
  
  let layerSort = (a, b) => {
    return sortingArr.indexOf(a) - sortingArr.indexOf(b);
  }
  // useWhyDidYouUpdate("Counter", props);
  return (
    <div className="menu-section">
      {Object.keys(editLayers).sort(layerSort).map((key, index) => {
        // If a limit is being passed in and does not include the key, don't show anything
        if (limit && limit.indexOf(key) === -1){
          // console.log("limit", limit, key)
          return null
        } 

        // Generate the filter for this layer if needed
        let filterWhere = generateFilter(editLayers[key].url)
        if (filterWhere){
          filterWhere = type + " AND " + filterWhere
        } else {
          filterWhere = type
        }
        if (editLayers[key].url.indexOf("Alerts") !== -1){
          if (!limit){
            // Don't show alerts on My Data
            return null
          }
          // Special case for alerts
          filterWhere = `(AlertOwnerMemberId = ${paverReg.id})`
        }

        // Otherwise, return the wrapper
        return <MenuWrapper
          key={"menusection"+index}
          layer={editLayers[key]}
          type={filterWhere}
          paverReg={paverReg}
          showCount={true}
          setCenter={setCenter}
          updateObj={updateObj}
          setUpdateObj={setUpdateObj}
        >
          {!otherOrgOuter ? ( // This is "My Data" or "Org Data"
            <Fragment> 
              {groupObj[key] === "Alerts" &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"OwnerLayer"}
                  defaultLabel={"No conflicting layer"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                >
                  <MenuSubset
                    layer={editLayers[key]}
                    type={filterWhere}
                    paverReg={paverReg}
                    editLayers={editLayers}
                    setCenter={setCenter}
                    subset={"ConflictOrgName"}
                    defaultLabel={"No organization"}
                    updateObj={updateObj}
                    setUpdateObj={setUpdateObj}
                  />
                </MenuSubset>
              }
              {(groupObj[key] === "Paving" && key.indexOf("Moratorium") !== -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"MorEnd"}
                  defaultLabel={"No Date"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              }
              {(groupObj[key] === "Paving" && key.indexOf("PCI") !== -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"StreetType"}
                  defaultLabel={"No Street Type"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              }
              {(groupObj[key] === "Paving" && key.indexOf("Moratorium") === -1 && key.indexOf("PCI") === -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"YearStart"}
                  defaultLabel={"No Date"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              }
              {groupObj[key] === "Utility" &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"SystemLevel"}
                  defaultLabel={"No System Level"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              }
              {groupObj[key] === "Projects" &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"ProjectName"}
                  defaultLabel={"No Project Name"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              }
            </Fragment>
          ) : (
            <Fragment> 
              {(groupObj[key] === "Paving" && key.indexOf("Moratorium") !== -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"PavingAuth"}
                  defaultLabel={"No Authority"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                >
                  <MenuSubset
                    layer={editLayers[key]}
                    type={filterWhere}
                    paverReg={paverReg}
                    editLayers={editLayers}
                    setCenter={setCenter}
                    subset={"MorEnd"}
                    defaultLabel={"No End Date"}
                    updateObj={updateObj}
                    setUpdateObj={setUpdateObj}
                  />
                </MenuSubset>
              }
              {(groupObj[key] === "Paving" && key.indexOf("PCI") !== -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"PavingAuth"}
                  defaultLabel={"No Authority"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                >
                  <MenuSubset
                    layer={editLayers[key]}
                    type={filterWhere}
                    paverReg={paverReg}
                    editLayers={editLayers}
                    setCenter={setCenter}
                    subset={"StreetType"}
                    defaultLabel={"No Street Type"}
                    updateObj={updateObj}
                    setUpdateObj={setUpdateObj}
                  />
                </MenuSubset>
              }
              {(groupObj[key] === "Paving" && key.indexOf("Moratorium") === -1 && key.indexOf("PCI") === -1) &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"PavingAuth"}
                  defaultLabel={"No Authority"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                >
                  <MenuSubset
                    layer={editLayers[key]}
                    type={filterWhere}
                    paverReg={paverReg}
                    editLayers={editLayers}
                    setCenter={setCenter}
                    subset={"YearStart"}
                    defaultLabel={"No Date"}
                    updateObj={updateObj}
                    setUpdateObj={setUpdateObj}
                  />
                </MenuSubset>
              }
              {/*{groupObj[key] === "Utility" &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={type}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"Owner"}
                  defaultLabel={"No Owner"}
                />
              }*/}
              {groupObj[key] === "Projects" &&
                <MenuSubset
                  layer={editLayers[key]}
                  type={filterWhere}
                  paverReg={paverReg}
                  editLayers={editLayers}
                  setCenter={setCenter}
                  subset={"MemberName"}
                  defaultLabel={"No Owner"}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                >
                  <MenuSubset
                    layer={editLayers[key]}
                    type={filterWhere}
                    paverReg={paverReg}
                    editLayers={editLayers}
                    setCenter={setCenter}
                    subset={"YearStart"}
                    defaultLabel={"No Date"}
                    updateObj={updateObj}
                    setUpdateObj={setUpdateObj}
                  />
                </MenuSubset>
              }
            </Fragment>
          )}
        </MenuWrapper>
      })}
    </div>
  )
}

export default MenuSection