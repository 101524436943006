import React, {useState, useRef, useEffect, Fragment, useContext} from 'react'
import { SubsetContext } from '../../contexts/subset'

const MenuWrapper = ({children, layer, noExpand, type, paverReg, whereAdd, showCount, setCenter, subset, titleOverride, limit, updateObj, setUpdateObj}) => {
  const [isOpen, setIsOpen] = useState(false)
  const [menuItems, setMenuItems] = useState([])
  const subsetContext = useContext(SubsetContext)
  const isMounted = useRef(false)

  let where = type
  if (subsetContext){
    where += subsetContext
  }

  const updateMenu = () => {
    if (
      !limit || // User gets all layers
      limit.indexOf(layer.title) !== -1 // This layer is included in the limit
    ){
      if (whereAdd){
        where += whereAdd // Added from parent
      }
      //console.log("WHERE", where)
      fetch(layer.url+"/query", {
        method: 'post',
        headers: { 'content-type': 'application/x-www-form-urlencoded' },
        body: new URLSearchParams({
          f: "json",
          token: paverReg.token,
          where: where,
          returnIdsOnly: true
        })
      }).then(resp => resp.json()).then(output => {
        //console.log("OUTPUT", output, where, layer.url)
        if (isMounted.current){
          if (output.objectIds && output.objectIds.length > 0){
            setMenuItems(output.objectIds)
          } else {
            setMenuItems([])
          }
          // Get ready for the next set
          setUpdateObj({})
        }
      }).catch(err => {
        console.log("Failed to fetch quickselects", err)
      })
    }
  }

  // On subsequent updates, only do so if we got targetted
  useEffect(() => {
    if (
      Object.keys(updateObj).indexOf(layer.title) !== -1 && !noExpand
      //Object.keys(updateObj).length === 0 // ACTUALLY this is bad idea because when it gets unset all the layers will refresh
    ) {
      setIsOpen(false)
      updateMenu()
    }
  }, [updateObj])

  // On the first update, just do so
  useEffect(() => {
    updateMenu()
    isMounted.current = true
    return () => { isMounted.current = false }
  }, [])

  // If it's all undefined, hide this
  if (menuItems && menuItems.length === 0){
    return null
  }

  return (
    <div className="menu-wrapper">
      <label onClick={() => {
        setIsOpen(!isOpen)
      }}>
        <div className={noExpand ? "menu-label no-expand" : "menu-label"}>
          {noExpand ? (
            <Fragment>{/*▶*/}</Fragment>
          ) : (
            <Fragment>
              {isOpen ? (
                <img src="/minus.svg" />
              ) : (
                <img src="/add.svg" />
              )}
            </Fragment>
          )}
          
          <span>{typeof titleOverride !== "undefined" ? titleOverride : layer.title} {showCount && "("+menuItems.length.toLocaleString()+")"}</span>
        </div>
        {menuItems &&
          <div className="group-select" title="Select this group" onClick={(e) => {
            e.stopPropagation()
            // let thisGroup = groupSelect
            // // Restrict the number for quick selects so it's managable
            // if (groupSelect.length > 10000){
            //   setShowDiffModal("overflow")
            //   thisGroup = groupSelect.slice(0, 10000)
            // }
            setCenter({items: menuItems, key: layer.title})
          }}>
            <img src="/select.svg" />
          </div>
        }
      </label>
      {isOpen &&
        <div className="menu-inner">
          {children}
        </div>
      }
    </div>
  )
}

export default MenuWrapper