import React, {useEffect, useState, useRef, Fragment} from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import 'react-responsive-combo-box/dist/index.css'

/* A modal to handle letting users know when there's a diff */

// Function just to stop the click from bubbling out and triggering the background exit
let interceptClick = (e) => {
  e.stopPropagation()
}

const DiffModal = ({showDiffModal, setShowDiffModal, setQueryResult, selection, selectKey, paverReg}) => {
  let modalRef = useRef(null)
  let [fade, setFade] = useState("")
  
  // On component init, change classes so it fades in
  useEffect(() => {
    // Fade it in
    setFade("show")

    // Lock body scroll
    if (modalRef.current){
      disableBodyScroll(modalRef.current)
    }

    // Enable on cleanup
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  return (
    <div className={"modal-wrapper " + fade} onMouseDown={() => {setShowDiffModal("")}}>
      <div className="drop-modal floating" ref={modalRef} onMouseDown={interceptClick}>
        <div className="data-entry query-entry">
          <div className="drop-options">
            {showDiffModal === "some" &&
              <Fragment>
                <p>Your selection contains features from other organizations! If you'd like to modify your organization's features, click the button below to reduce the selection.</p>
                <button className="primary" onClick={(e) => {
                  e.stopPropagation()
                  setShowDiffModal("")
                  // Grab all the ones that match this organization
                  let newSelect = []
                  for (let item in selection){
                    let thisItem = selection[item]
                    if (thisItem.attributes.MemberName === paverReg.org){
                      newSelect.push(thisItem)
                    }
                  }
                  // Use the query logic to do the reduce
                  setQueryResult({[selectKey]: newSelect})
                }}>
                  Reduce
                </button>
              </Fragment>
            }
            {showDiffModal === "all" &&
              <p>Your selection contains no features from your organization. Organizations can only edit or delete features that belong to them.</p>
            }
            {showDiffModal === "pm" &&
              <p>This layer is not editable by users with the Project Manager role. Please contact your organization's admin to change your role or make these edits.</p>
            }
            {showDiffModal === "overflow" &&
              <p>This quick select contains over 10,000 features. Only the first 10,000 have been selected. Please use the query tool to refine or expand your selection if needed.</p>
            }
            <button className="secondary" onClick={(e) => {
              e.stopPropagation()
              setShowDiffModal("")
            }}>Close</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DiffModal