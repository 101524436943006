import React, {useEffect, useState, Fragment, useRef, useContext} from 'react'
import Nav from '../ui/nav'
import EsriMap from './esrimap'
import DropModal from '../modals/dropmodal'
import QueryModal from '../modals/querymodal'
import DiffModal from '../modals/diffmodal'
import ErrorModal from '../modals/errormodal'
import AlertModal from '../modals/alertmodal'
import MenuSection from '../ui/menusection'
import DragBar from '../ui/dragbar'
import { useCookies } from 'react-cookie'
import { projectManagerEditLayers, decodeCookie } from '../../utils/constants.js'
import { otherOrgQuickLayers, orgQuickLayers } from '../../utils/constants.js'
import { LoaderContext } from '../../contexts/loader'
import ImportExportModal from '../modals/importexportmodal'

let currentEnv = process.env.GATSBY_ENV
// For the purposes of this test, dev is stage too
if (currentEnv !== "prod"){
  currentEnv = "stage"
}

/* Encompasses the context of the app so that state can be contained and referenced */

const MapContext = () => {
  let [showDropzone, setShowDropzone] = useState(false)
  let [showQueryModal, setShowQueryModal] = useState(false)
  let [showExportModal, setShowExportModal] = useState(false)
  let [showAlertModal, setShowAlertModal] = useState(false)
  let [alertData, setAlertData] = useState(null)
  let [showDiffModal, setShowDiffModal] = useState("")
  let [showErrorModal, setShowErrorModal] = useState("")
  let [uploadSelect, setUploadSelect] = useState({})
  let [updateObj, setUpdateObj] = useState({})
  let [loadingOrgs, setLoadingOrgs] = useState([])
  let [selection, setSelection] = useState([]) // The actual selection you can manipulate (one layer)
  let [preSelection, setPreSelection] = useState({}) // The selection of all things in range from any layer
  let [hoverKey, setHoverKey] = useState(null) // The layer we're hovering over from the preselect menu
  let [selectKey, setSelectKey] = useState(null) // The key of what we've selected from the preselect
  let [showTable, setShowTable] = useState(false)
  let [suspendTable, setSuspendTable] = useState(false)
  let [center, setCenter] = useState(null)
  let [resizeMenu, setResizeMenu] = useState(310)
  let [queryResult, setQueryResult] = useState({}) // A way to pass data out of the query and over to the map for selection
  let [view, setView] = useState(null) // Pass view back up from EsriMap
  let menuRef = useRef(null)
  const [cookies, setCookie, removeCookie] = useCookies(['paverReg'])

  // Set modules from context
  const loaderContext = useContext(LoaderContext)
  const modules = loaderContext

  // Safety check so we don't crash the app
  if (!cookies.paverReg){
    cookies.paverReg = {}
  } else {
    // Decode the result
    cookies.paverReg = decodeCookie(cookies.paverReg)
  }

  // Set classes for the table 
  let lockScroll = true
  let tableClass = ""
  if (!showTable){
    tableClass += " hidden"
    lockScroll = false
  }
  if (suspendTable){
    tableClass += " suspended"
    lockScroll = false
  }

  // Set classes for the preselect 
  let preselectClass = ""
  // Check if this object is falsey, OR we only have 1 key
  let preSelectedMulti = !preSelection.multiSelectMap || Object.keys(preSelection.multiSelectMap).length <= 1
  // Also hide the preselect if we have a real select
  if (preSelectedMulti || selection.length > 0){
    preselectClass += " hidden"
  } else {
    lockScroll = true
  }

  useEffect(() => {
    // Start by checking for any alerts we need to show the user
    const fetchAlert = (year, month) => {
      return new Promise(async (resolve, reject) => {
        try {
          const fetchURL = `https://paverops.s3.amazonaws.com/upload-alerts/${cookies.paverReg.id}/${year}/${month}/alerts-${currentEnv}.json`
          const fetchResp = await fetch(fetchURL)
          const fetchJSON = await fetchResp.json()
          // If we have a response, set the alert data
          if (fetchJSON){
            resolve(fetchJSON)
          } else {
            // Resolve empty
            resolve([])
          }
        } catch (err) {
          // This could very likely 403, but that's fine -- just means there are no alerts
          resolve([])
        }
      })
    }
    // Add one more item in the stack for entries on the alerts layer
    const layerPromise = new Promise(async (resolve, reject) => {
      // Fetch the alerts layer to make the query
      let alertsLayerKey = "Alerts"
      if (currentEnv !== "prod"){
        alertsLayerKey = "Alerts Test"
      }
      // If alerts layer isn't loaded, bail
      if (!modules.editLayers[alertsLayerKey]){
        resolve([])
        return
      }
      // Fetch 'em
      const queryResult = await modules.editLayers[alertsLayerKey].queryFeatures({
        // Get all outfields
        outFields: ["*"],
        returnGeometry: false,
        where: "1=1"
      })
      // Now that we have the results, format the features into this structure:
      /*
      date: "2023-12-11"
      id: 16
      layer: "Project Test"
      layer_id: "Project_Test"
      new: 1
      org: "Separate Test Org"
      select: "2023-12-04"
      */
      const alertLayerObj = {}
      // Combine alerts from the same day
      for (let i = 0; i < queryResult.features.length; i++){
        const thisAlertObj = {}
        const attrs = queryResult.features[i].attributes
        // Convert unix timestamp to YYYY-MM-DD
        const dateObj = new Date(attrs.CreationDate)
        const isoString = dateObj.toISOString()
        const keyDate = `${isoString.split("T")[0]}`
        if (!alertLayerObj[keyDate]){
          alertLayerObj[keyDate] = []
        }
        // Add to the array
        alertLayerObj[keyDate].push(attrs)
      }
      // Ok now that we've sorted everything, structure the final object
      const alertLayerArray = []
      for (let key in alertLayerObj){
        const outputObj = {alertType: "intersection"}
        // New is just the # of items in the array
        outputObj.new = alertLayerObj[key].length
        // layer_id is always the alert layer
        outputObj.layer = alertsLayerKey
        outputObj.layer_id = alertsLayerKey.replace(/ /g, "_")
        outputObj.org = "SOME ORG"
        // Always the memberId
        outputObj.id = cookies.paverReg.id
        // Get the first alert
        const attrs = alertLayerObj[key][0]
        // Convert unix timestamp to YYYY-MM-DD
        const dateObj = new Date(attrs.CreationDate)
        let isoString = dateObj.toISOString()
        outputObj.date = `${isoString.split("T")[0]}`
        // Select obj should be ON the date (captures anything that happened that day)
        // TODO: Maybe this means we should update the logic the other alerts use?
        const selectDate = new Date(dateObj)
        selectDate.setDate(selectDate.getDate())
        isoString = selectDate.toISOString()
        outputObj.select = `${isoString.split("T")[0]}`
        // Add to the array
        alertLayerArray.push(outputObj)
      }
      resolve(alertLayerArray);
    })
    const promiseStack = [layerPromise] // Holds all promises
    // Loop 3 times (3 month alert window)
    for (let i = 0; i < 3; i++){
      // Get the current year and month
      let currentYear = new Date().getFullYear()
      let currentMonth = new Date().getMonth() + 1 - i
      // Roll backward if needed
      if (currentMonth < 1){
        currentMonth = 12
        currentYear = currentYear - 1
      }
      const newPromise = fetchAlert(currentYear, currentMonth)
      promiseStack.push(newPromise)
    }
    // When all promises are resolved, set the alert data
    Promise.all(promiseStack).then((values) => {
      // Combine all the values into one array
      let alertDataResp = []
      values.forEach((value) => {
        alertDataResp = alertDataResp.concat(value)
      })
      // Sort values by date
      alertDataResp.sort((a, b) => {
        return new Date(b.date) - new Date(a.date)
      })
      
      // Loop through the alerts and see if we have a cookie for it
      if (cookies.paverAlerts){
        let cookieVal = cookies.paverAlerts
        cookieVal = cookieVal.split("|||")
        alertDataResp.forEach((alert) => {
          if (cookieVal.indexOf(`${alert.org}_${alert.layer}_${alert.date}`) !== -1){
            alert.seen = true
          }
        })
      }

      // Update what the user sees
      setAlertData(alertDataResp)
    })
      
    // // Fake a load for now
    // setTimeout(() => {
    //   // TODO: Replace with fetched data instead of just providing it
    //   const alertDataResp = [
    //     {
    //       "org": "PaverOps",
    //       "id": 5,
    //       "new": 1,
    //       "layer": "Paving Plan Test",
    //       "layer_id": "Paving_Plan_Test",
    //       "date": "2023-11-26", // Date the alert was created
    //       "select": "2020-01-01", // Past date we'll start the select from
    //     }, {
    //       "org": "EBMUD",
    //       "id": 3,
    //       "new": 12,
    //       "layer": "Project",
    //       "layer_id": "Paving_Plan_Test",
    //       "date": "2020-01-01",
    //       "select": "2020-01-01",
    //     }
    //   ]
      
    //   // Combine the org, layer and date to make a unique key
    //   let cookieVal = ""
    //   // Load existing cookie val if it exists
    //   if (cookies.paverAlerts){
    //     cookieVal = cookies.paverAlerts
    //   }
    //   // Split the val into an array
    //   cookieVal = cookieVal.split("|||")
    //   // For each val, try to match it to an alert (and mark seen if so)
    //   cookieVal.forEach((cookie) => {
    //     const foundAlert = alertDataResp.find((alert) => {
    //       return `${alert.org}_${alert.layer}_${alert.date}` === cookie
    //     })
    //     if (foundAlert){
    //       foundAlert.seen = true
    //     } else {
    //       // If we can't find it, remove it from the cookie (must have expired)
    //       cookieVal = cookieVal.filter((cookie) => {
    //         return cookie !== `${alert.org}_${alert.layer}_${alert.date}`
    //       })
    //     }
    //   })

    //   // Update what the user sees
    //   setAlertData(alertDataResp)
    // }, 1000)
  }, [])

  useEffect(() => {
    // If the table or preselect is being shown, make sure we scroll to the top of the div
    if (menuRef.current){
      if (showTable || preselectClass.indexOf("hidden") === -1){
        menuRef.current.scrollTop = 0
      }
    }  
  }, [showTable, preSelection])
  return (
    <div className="map-context">
      <Nav
        showAlertModal={showAlertModal}
        setShowAlertModal={setShowAlertModal}
        alertNumber={alertData && alertData.filter((alert) => {
          return !alert.seen
        }).length}
        items={[
          {url: "/account/", text: "Account"},
          {url: "/support/", text: "Support Hub"},
        ]
      } />
      
      <section className="app-body">
        <div className="flex-wrapper">
          <div className="main-map">
            <EsriMap 
              modules={modules}
              loadingOrgs={loadingOrgs}
              setLoadingOrgs={setLoadingOrgs}
              setUpdateObj={setUpdateObj}
              center={center} 
              paverReg={cookies.paverReg}
              showDropzone={showDropzone} // Pass in to refresh the map when closing
              setShowDropzone={setShowDropzone} // Pop the drop if we're drawing
              setShowQueryModal={setShowQueryModal} // Pop the the query modal on button click
              setShowDiffModal={setShowDiffModal} // Pop the the diff modal on button click
              setShowExportModal={setShowExportModal} // Pop the the export modal on button click
              selection={selection}
              setSelection={setSelection}
              setShowTable={setShowTable}
              setSuspendTable={setSuspendTable}
              preSelection={preSelection}
              setPreSelection={setPreSelection}
              selectKey={selectKey}
              setSelectKey={setSelectKey}
              hoverKey={hoverKey}
              queryResult={queryResult}
              setView={setView}
              uploadSelect={uploadSelect}
            />
          </div>
          <DragBar setResizeMenu={setResizeMenu} />
          <div className="menu" ref={menuRef} style={{flex: `0 0 ${resizeMenu}px`, overflow: lockScroll ? "hidden" : "scroll"}}>
            <div id="table-outer" className={tableClass}>
              <div id="table-close-box" onClick={() => {
                setSuspendTable(!suspendTable)
              }}>
                { suspendTable ? (
                  <Fragment><img src="../list.png" />Show table</Fragment>
                ) : (
                  <Fragment><img src="../close-button.png" />Hide table</Fragment>
                )}
              </div>
              <div id="table-wrapper" className={showTable === "warn" ? "warn-shrink" : ""} />
              {showTable === "warn" &&
                <div id="table-truncate-note">
                  Only the first 2,000 results appear in this table. Refine your selection if needed.
                </div>
              }
            </div>
            <div id="preselect-outer" className={preselectClass}>
              <p>Features selected from multiple layers! Please select which layer you'd like to edit.</p>
              {preSelection.multiSelectMap && Object.keys(preSelection.multiSelectMap).map((key) => {
                return <p className="preselect-row" key={key}><a 
                  onClick={() => {
                    // Set the key on state so we know what URL to grab
                    setSelectKey(key)
                    // Not ideal but it works -- we are passing all the info we need to handle the selectFeatures function up from EsriMap
                    const lockAll = cookies.paverReg.role === 'Viewer' || (cookies.paverReg.role === 'Project Manager' && projectManagerEditLayers.indexOf(key) === -1)
                    preSelection.selectFeatures(preSelection.multiSelectMap[key], preSelection.editLayers[key], lockAll, preSelection.form, preSelection.view, preSelection.Graphic, selection, setSelection)
                  }}
                  onMouseEnter={() => {
                    // Set the hoverKey to thin out the select
                    setHoverKey(key)
                  }}
                  onMouseLeave={() => {
                    // Set to null to remove the filter
                    setHoverKey(null)
                  }}
                >{key}: {preSelection.multiSelectMap[key].length} feature(s)</a></p>
              })}
              <p className="preselect-row"><a onClick={() => {
                // Clear preselect
                setPreSelection({})
              }}>Clear (Esc)</a></p>
            </div>
            {modules.layerError &&
              <div className="layer-error">Some layers failed to load. Please reload to refresh missing layers.</div>
            }
            {/* If we have creation permissions, present an upload button */}
            {['Creator', 'Project Manager'].indexOf(cookies.paverReg.role) !== -1 &&
              <div className="button-wrapper">
                <button className="primary" id="menu-upload-button" onClick={() => {
                  // Bail if we're busy
                  if (document.getElementById("drawing-help")) return

                  setShowDropzone(true)
                }}><img src="/upload.svg" />Upload Data</button>
                <button className="primary" id="menu-draw-button"><img src="/drawing.svg" />Create Feature</button>
              </div>
            }   
            {loadingOrgs.length > 0 &&
              <div className="org-loading">Loading quick select groups <img className="loading" src="/loading.png" alt="Loading icon" /></div>
            }
            {['Creator', 'Project Manager'].indexOf(cookies.paverReg.role) !== -1 &&
              <Fragment>
                <h2>My data</h2>
                {/* 
                  Expandable subsections: Org Owner > Layer > Project 
                  Obviously Org Owner only valid for "Other Org" data section
                */}
                {/*<MenuSection 
                  allProjects={allProjects}
                  allSystemLevel={allSystemLevel}
                  allYearStart={allYearStart}
                  allEndDate={allEndDate}
                  allStreetType={allStreetType}
                  orgData={orgData} 
                  type={"user"}  
                  setCenter={setCenter}
                  paverReg={cookies.paverReg}
                  groupLayers={modules.groupLayers}
                  setShowDiffModal={setShowDiffModal}
                />*/}
                <MenuSection
                  // We were using APICreator, but created_user is more helpful because it can't be NULL
                  //type={`(MemberId <> NULL AND created_user = '${cookies.paverReg.username}')`}
                  type={`(created_user = '${cookies.paverReg.username}')`} // Just the user's data
                  setCenter={setCenter}
                  paverReg={cookies.paverReg}
                  groupLayers={modules.staticGroupLayers}
                  editLayers={modules.staticEditLayers}
                  updateObj={updateObj}
                  setUpdateObj={setUpdateObj}
                />
              </Fragment>
            }
            <h2 className={['Creator', 'Project Manager'].indexOf(cookies.paverReg.role) !== -1 ? "" : "no-margin"}>My organization's projects</h2>

            {/*<MenuSection 
              allProjects={allProjects}
              allSystemLevel={allSystemLevel}
              allYearStart={allYearStart}
              allEndDate={allEndDate}
              allStreetType={allStreetType}
              orgData={orgData} 
              type={"org"}
              setCenter={setCenter}
              paverReg={cookies.paverReg}
              groupLayers={modules.groupLayers}
              setShowDiffModal={setShowDiffModal}
            />*/}
            <MenuSection
              limit={orgQuickLayers} 
              // We were using APICreator, but created_user is more helpful because it can't be NULL
              //type={`(MemberId = ${cookies.paverReg.id} AND created_user <> '${cookies.paverReg.username}')`} // Just the org data
              type={`(MemberId = ${cookies.paverReg.id})`} // Actually include the user's data too
              setCenter={setCenter}
              paverReg={cookies.paverReg}
              groupLayers={modules.staticGroupLayers}
              editLayers={modules.staticEditLayers}
              updateObj={updateObj}
              setUpdateObj={setUpdateObj}
            />
            
            <h2>Other organizations' projects</h2>

            {/*<MenuSection 
              allProjects={allProjects} 
              allOwners={allOwners}
              allYearStart={allYearStart}
              allEndDate={allEndDate}
              allPavingAuth={allPavingAuth}
              allStreetType={allStreetType}
              orgData={orgData} 
              type={"other_org"}
              setCenter={setCenter}
              paverReg={cookies.paverReg}
              groupLayers={modules.groupLayers}
              setShowDiffModal={setShowDiffModal}
            />*/}

            <MenuSection 
              limit={otherOrgQuickLayers} 
              //type={`MemberId <> NULL AND MemberId <> ${cookies.paverReg.id}`} 
              type={`MemberId <> ${cookies.paverReg.id}`} // All visible data
              setCenter={setCenter}
              paverReg={cookies.paverReg}
              groupLayers={modules.staticGroupLayers}
              editLayers={modules.staticEditLayers}
              updateObj={updateObj}
              setUpdateObj={setUpdateObj}
              otherOrgOuter={true}
            />

          </div>
        </div>
        {(showDropzone === true || typeof showDropzone === "object") &&
          <DropModal 
            setShowDropzone={setShowDropzone} 
            dropzoneData={showDropzone} // bool true will show fresh dropzone, but passing in an array means we're submitting a drawing
            paverReg={cookies.paverReg}
            editLayers={modules.editLayers}
            setUploadSelect={setUploadSelect}
            setShowErrorModal={setShowErrorModal}
            modules={modules}
          />
        }
        {showQueryModal &&
          <QueryModal 
            setShowQueryModal={setShowQueryModal} 
            setQueryResult={setQueryResult}
            paverReg={cookies.paverReg}
            editLayers={modules.editLayers}
            modules={modules}
            view={view}
          />
        }
        {showDiffModal &&
          <DiffModal 
            showDiffModal={showDiffModal}
            setShowDiffModal={setShowDiffModal}
            setQueryResult={setQueryResult}
            selection={selection}
            selectKey={selectKey}
            paverReg={cookies.paverReg}
          />
        }
        {showExportModal &&
          <ImportExportModal
            setShowImportModal={setShowExportModal}
            paverReg={cookies.paverReg}
            type="export"
            selection={selection}
            editLayers={modules.editLayers}
            selectKey={selectKey}
          />
        }
        {showErrorModal &&
          <ErrorModal
            showErrorModal={showErrorModal}
            setShowErrorModal={setShowErrorModal}
          />
        }
        {(showAlertModal && preSelection) &&
          <AlertModal
            showAlertModal={showAlertModal}
            setShowAlertModal={setShowAlertModal}
            alertData={alertData}
            setAlertData={setAlertData}
            setQueryResult={setQueryResult}
            editLayers={modules.editLayers}
          />
        }
      </section>
    </div>
  )
}

export default MapContext