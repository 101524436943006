import React from 'react'
import Layout from '../components/layout'
import Validate from '../components/validate'
import MapLoader from '../components/map/maploader'
import MapContext from '../components/map/mapcontext'

// Get the URLs depending on our environment
let redirectUri = 'http://localhost:8888/oauth-callback'
let lambdaUri = 'http://localhost:8888'

const currentEnv = process.env.GATSBY_ENV
if (currentEnv === "prod"){
  redirectUri = 'https://www.paverops.com/oauth-callback'
  lambdaUri = 'https://www.paverops.com'
}
if (currentEnv === "stage"){
  redirectUri = 'https://stage--paverops.netlify.app/oauth-callback'
  lambdaUri = `https://stage--paverops.netlify.app`
}

// markup
const App = () => {
  return (
    <Layout>
      <main>
        <Validate lambdaUri={lambdaUri} redirectUri={redirectUri}>
          <MapLoader lambdaUri={lambdaUri}>
            <MapContext />
          </MapLoader>
        </Validate>
      </main>
    </Layout>
  )
}

export default App

