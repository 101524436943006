import React, {useEffect, useState, useRef, Fragment} from 'react'
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import 'react-responsive-combo-box/dist/index.css'
import { useCookies } from 'react-cookie'

/* A modal to handle showing what went wrong with an upload */

// Function just to stop the click from bubbling out and triggering the background exit
let interceptClick = (e) => {
  e.stopPropagation()
}

const AlertModal = ({showAlertModal, setShowAlertModal, alertData, setAlertData, setQueryResult, editLayers}) => {
  let modalRef = useRef(null)
  let [fade, setFade] = useState("")
  const [cookies, setCookie, removeCookie] = useCookies(['paverAlerts', 'paverReg'])
  
  // On component init, change classes so it fades in
  useEffect(() => {
    // Fade it in
    setFade("show")

    // Lock body scroll
    if (modalRef.current){
      disableBodyScroll(modalRef.current)
    }
    
    // Combine the org, layer and date to make a unique key
    // NOTE: This gets set fresh every time (also helpful to remove stale alerts)
    let cookieVal = ""
   
    // Add to cookie string
    alertData.forEach((alert) => {
      let alertKey = `${alert.org}_${alert.layer}_${alert.date}`
      cookieVal += alertKey + "|||"
    })
    
    // Mark these as seen for the future
    setCookie('paverAlerts', cookieVal, { path: '/' })

    // Enable on cleanup
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [])

  const markAllSeen = () => {
    // Mark these as seen for the future
    const tempAlertData = JSON.parse(JSON.stringify(alertData))
    for (let i = 0; i < tempAlertData.length; i++){
      tempAlertData[i].seen = true
    }
    setAlertData(tempAlertData)
  }

  return (
    <div className={"modal-wrapper " + fade} onClick={() => {
      setShowAlertModal(false)
    }}>
      <div className="drop-modal floating" ref={modalRef} onMouseDown={interceptClick}>
        <div className="data-entry query-entry">
          <div className="drop-options">
            <p>Recent alerts for your organization</p>
            <div className="alert-scroll-box">
              {alertData ? (
                <ul className="alert-list">
                  {alertData.map((alert, i) => {
                    // Prepare dates, format as month day, year
                    // Add hours to date to get it to display correctly (it's going a day back since it starts at midnight in UTC and translates timezones)
                    const betterDate = new Date(alert.date)
                    betterDate.setHours(betterDate.getHours() + 8)
                    const prettyDate = betterDate.toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "short",
                      day: "numeric",
                    });
                    // We need another date config because ArcGIS won't accept the "<=" operator for dates that should match on the current day
                    // Instead, use "<" and add a day to the date
                    const addedDayDate = new Date(alert.date)
                    addedDayDate.setDate(addedDayDate.getDate() + 1)
                    const computerDate = addedDayDate.toISOString().split("T")[0]
                    return (
                      <li key={i} onClick={() => {
                        // Use the alert.select date to select the features that were uploaded after this timestamp, return only IDs
                        // Already restricted to visibleArea because we are using the view
                        const dateQuery = alert.alertType === "intersection" ? 
                          `CreationDate > DATE '${alert.select}' AND CreationDate < DATE '${computerDate}'` 
                        : `created_date > DATE '${alert.select}' AND created_date < DATE '${computerDate}' AND MemberId = '${alert.id}'`
                        if (editLayers[alert.layer]){ // Wrapping check in case we get a weird edge case
                          editLayers[alert.layer].queryObjectIds({
                            where: dateQuery,
                          }).then((results) => {
                            // Set "seen" status for this alert
                            const tempAlertData = JSON.parse(JSON.stringify(alertData))
                            tempAlertData[i].seen = true
                            setAlertData(tempAlertData)
                            // Now that we have IDs, select them
                            setShowAlertModal(false)
                            setQueryResult({[alert.layer]: results})
                          })
                        }
                      }}>
                        <div className={!alert.seen ? "new-alert-tick" : "new-alert-tick seen"} />
                        <div>
                          <div><strong>{prettyDate}</strong></div>
                          {alert.alertType === "intersection" ? (
                            <>
                              {alert.new} new conflict{alert.new > 1 ? "s" : ""} with other organizations' features. <a>See collaboration opportunities &raquo;</a>
                            </>
                          ) : (
                            <>
                              {alert.org} added {alert.new} new feature{alert.new > 1 ? "s" : ""} to the {alert.layer} layer{alert.id !== cookies.paverReg.id ? " in your service area" : ""}. <a>See what was added &raquo;</a>
                            </>
                          )}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              ) : (
                <div className="loading">
                  <img src="/loading.png" alt="Loading icon" />
                </div>
              )}
            </div>
            <div className='button-row'>
              <button className="secondary" onClick={(e) => {
                e.stopPropagation()
                setShowAlertModal(false)
              }}>Close</button>
              <button className="secondary" onClick={(e) => {
                e.stopPropagation()
                markAllSeen()
              }}>Clear Alerts</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AlertModal