import React, {useState} from 'react'

// A draggable bar to resize the menu + map

const DragBar = ({setResizeMenu}) => {
  let [resizeIndicator, setResizeIndicator] = useState(false)

  let logDrag = () => {
    // Show we're dragging
    setResizeIndicator(true)

    // Function to follow the mouse
    let trackMouse = (e) => {
      e.preventDefault() // Prevent highlighting by accident
      if (e.clientX !== 0){
        // Calculate the offset and adjust the sizes
        let offsetDiff = window.innerWidth - e.clientX - 15
        // If the offset diff is less than minimum, the menu is pretty much useless so go ahead and hide it
        if (offsetDiff < 200){
          offsetDiff = 0
        }
        setResizeMenu(offsetDiff)
      }
    } 

    // Set up events! This will self-destruct when mouseup occurs
    document.addEventListener('mousemove', trackMouse)
    document.addEventListener('mouseup', function remover(){
      // Remove drag indicator
      setResizeIndicator(false)
      document.removeEventListener('mousemove', trackMouse)
      document.removeEventListener('mouseup', remover)
    }) 
  }

  return (
    <div className={resizeIndicator ? "resizer active" : "resizer"} onMouseDown={logDrag}>
      <div className="resizer-inner" />
    </div>
  )
}

export default DragBar