import React, {useState, Fragment} from 'react'
import { chunkOperation } from '../../utils/esri-helpers'

// Separated out the "submit and replace" button because it was getting unwieldy

const ReplaceButton = ({editLayers, uploadLayer, uploadFunc, paverReg, replaceMsg, setReplaceMsg, uploadMsg, esriRequest}) => {
  let [batch, setBatch] = useState({
    current: 0,
    max: 0
  })

  return (
    <button className="critical" onClick={(e) => {
      e.stopPropagation()
      // Quit out if another upload is in progress
      if (["final-upload", "success"].indexOf(uploadMsg) !== -1){
        return false
      }
      // Set the warning
      if (replaceMsg === ""){
        setReplaceMsg("warn")
      } else if (replaceMsg === "warn") {
        // Handle the actual replace!
        // Prevent button mashing
        setReplaceMsg("final-replace")
        // First, do a query that returns all the layer features that this org owns
        // We only need OBJECTIDs here
        editLayers[uploadLayer].queryObjectIds({
          where: `MemberName = '${paverReg.org}'`
        })
        .then((results) => {
          //console.log("RESULTS", results)
          chunkOperation(esriRequest, "deleteFeatures", results, editLayers[uploadLayer], null, setBatch).then((failMsg) => {
            if (failMsg){
              console.log("===============================================");
              console.error(
                "[ applyEdits ] FAILURE: ",
                failMsg
              )
              //console.log("error = ", failMsg);
            }
            // -1 will show the uploading msg
            setBatch({
              current: -1,
              max: -1
            })
            // TODO: If there is an error in the result, we should pause this process and alert it, like we do for the upload process
            // Once the delete is done, start the upload
            uploadFunc(true) // True flag will update the right message
          })
        })    

        // TODO: Test this to see if we can get it working, could be a lot faster
        // We would need to build in polling to check on the state of the job after it's submitted
        
        // If we pass a where query directly to the /deleteFeatures endpoint, we don't need to paginate!
        // let formData = new FormData()
        // formData.append('token', paverReg.token)
        // formData.append('where', `MemberName = '${paverReg.org}'`)
        // formData.append('async', true)
        // fetch(editLayers[uploadLayer].url+"/0/deleteFeatures?f=json", {
        //   method: 'post',
        //   body: formData
        // }).then(response => {
        //   return response.json()
        //   // Return the upload URL
        // }).then((output) => {
        //   console.log(output)
        //   uploadFunc(true)
        // })
      }
    }}>
      <Fragment>
        {replaceMsg === "success" &&
          <span>{uploadLayer} layer replaced!</span>
        }
        {replaceMsg === "final-replace" &&
          <Fragment>
            {batch.current > 0 &&
              <span>Batch {batch.current} of {batch.max}</span>
            }
            {batch.current === -1 &&
              <span>Uploading...</span>
            }
            <img className="loading" src="/loading.png" alt="Loading icon" />
          </Fragment>
        }
        {replaceMsg === "warn" &&
          <span>⚠️&nbsp;&nbsp;Confirm replace: {uploadLayer}&nbsp;⚠️</span>
        }
        {replaceMsg === "" &&
          <span>Submit and replace layer</span>
        }
      </Fragment>
    </button>
  )
}

export default ReplaceButton